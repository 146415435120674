$(window).on('load', function () {
	$('#preloader .inner').fadeOut();
	$('#preloader').delay(5).fadeOut('slow');
	$('body').delay(5).css({ 'overflow': 'visible' });
})

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

var offcanvasElementList = [].slice.call(document.querySelectorAll('.offcanvas'))
var offcanvasList = offcanvasElementList.map(function (offcanvasEl) {
	return new bootstrap.Offcanvas(offcanvasEl)
})

const cards = document.querySelectorAll('.card');

let operative = document.querySelector('.operative');

cards.forEach(card => {
	card.addEventListener('mouseover', () => {
		operative.classList.remove('active');
		card.classList.add('active');
	});
	card.addEventListener('mouseout', () => {
		card.classList.remove('active');
		operative.classList.add('active');
	});
});

$(document).ready(function () {
	$('.btn-stage').on('mouseenter', function () {
		$(this).addClass("active").prevAll().addClass("active");
		$(this).prevAll().removeClass("active");
		$(this).nextAll().removeClass("active");

		var stage = parseInt($(this).data('stage'));
		var width = stage * 20;
		$('.progress-bar').css('width', width + '%').attr('aria-valuenow', width);

		$(".order0" + stage).addClass("active").siblings().removeClass("active");
		$(".stage-image0" + stage).addClass("active").siblings().removeClass("active");
	})
});

$(document).ready(function () {
	$('.nav-item-dropdown').on('mouseenter', function () {
		$(this).addClass("active").prevAll().addClass("active");
		$(this).prevAll().removeClass("active");
		$(this).nextAll().removeClass("active");

		var subitem = parseInt($(this).data('subitem'));

		$(".subnav-item0" + subitem).addClass("active").siblings().removeClass("active");
	});

	$('.navbar').on('mouseleave', function () {
		$('.nav-item-dropdown.active').removeClass("active");
		$('.subnav-content.active').removeClass("active");
	});
});

function rotateObject(object) {
	// Verifique a rotação atual do objeto
	let currentRotation = getComputedStyle(object).getPropertyValue('transform');

	// Se o objeto ainda não foi rotacionado, defina a rotação para 180 graus
	if (currentRotation === 'none') {
		object.style.transform = 'rotate(-180deg)';
	} else {
		// Se o objeto já foi rotacionado, remova a rotação
		object.style.transform = 'none';
	}
}



/* $(window).scroll(function() {
	if ($(document).scrollTop() > 50) {
	  $('.navbar').addClass('navbar-shrink');
	} else {
	  $('.navbar').removeClass('navbar-shrink');
	}
  }); */

new WOW().init();